import { Helmet } from "react-helmet-async";
import axios from 'axios';

export default function Contact() {
    const sendMail = (event) => {
        event.preventDefault()
        const formData = new FormData(event.target);
        let first_name = formData.get('first_name')
        let last_name = formData.get('last_name')
        let company = formData.get('company_name')
        let email_address = formData.get('email_address')
        let contact_number = formData.get('contact_number')
        let role = formData.get('role')
        let message = formData.get('message')
        console.log(first_name, last_name, company, email_address, contact_number, role, message);

        return axios({
            method: "post",
            url: "/assets/contact.php",
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
          })
            .then(function (response) {
              //handle success
              console.log(response);
            })
            .catch(function (response) {
              //handle error
              console.log(response);
            });
    }
    return (<>
        <Helmet>
            <title>Contact | Velozent</title>
        </Helmet>
        <div className="py-16 lg:py-20 px-4">
            <div className="container mx-auto">
                <p className="text-burnt-sienna-500 font-bold text-xl text-center mb-6">CONTACT US</p>
                <p className="font-semibold text-3xl text-center mb-2">We Want to Hear from You</p>
                <p className="text-xl text-center mb-24 text-gray-500">Elevate your business with End-to-End healthcare technology services</p>
                <div className="grid grid-cols-1 lg:grid-cols-3 lg:space-x-6">
                    <div className="space-y-6 mb-16 lg:mb-0">
                        <div className="flex items-center">
                            <div className="mr-8 text-white bg-burnt-sienna-500 rounded-full h-12 w-12 justify-center flex flex-row items-center"><i className="fa-solid fa-location-dot"></i></div>
                            <div>
                                <p className="mb-2 text-xl font-semibold">Location</p>
                                <p className="text-gray-500">UCI Beall Applied Innovations <br />5270 California Ave #100, Irvine, CA, 92679</p>
                            </div>
                        </div>
                        <div className="flex items-center">
                            <div className="mr-8 text-white bg-burnt-sienna-500 rounded-full h-12 w-12 justify-center flex flex-row items-center"><i className="fa-solid fa-phone"></i></div>
                            <div>
                                <p className="mb-2 text-xl font-semibold">Phone</p>
                                <p className="text-gray-500"><a href="tel:+19096393504">+1 909 639 3504</a></p>
                            </div>
                        </div>
                        <div className="flex items-center">
                            <div className="mr-8 text-white bg-burnt-sienna-500 rounded-full h-12 w-12 justify-center flex flex-row items-center"><i className="fa-solid fa-envelope"></i></div>
                            <div>
                                <p className="mb-2 text-xl font-semibold">Email</p>
                                <p className="text-gray-500"><a href="mailto:connect@velozent.com">connect@velozent.com</a></p>
                            </div>
                        </div>
                    </div>
                    <form className="lg:col-span-2" onSubmit={sendMail}>
                        <div className="flex flex-wrap mb-6">
                            <div className="w-full lg:w-1/2 lg:px-3 mb-6 lg:mb-0">
                                <label className="block tracking-wide text-gray-700 text-sm font-bold mb-2" htmlFor="grid-first-name">
                                    First Name
                                </label>
                                <input className="appearance-none block w-full text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none" id="grid-first-name" type="text" placeholder="First Name" name="first_name" required />
                            </div>
                            <div className="w-full lg:w-1/2 lg:px-3">
                                <label className="block tracking-wide text-gray-700 text-sm font-bold mb-2" htmlFor="grid-last-name">
                                    Last Name
                                </label>
                                <input className="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:border-gray-500" id="grid-last-name" type="text" placeholder="Last Name" name="last_name" required />
                            </div>
                        </div>
                        <div className="flex flex-wrap mb-6">
                            <div className="w-full lg:w-1/2 lg:px-3">
                                <label className="block tracking-wide text-gray-700 text-sm font-bold mb-2" htmlFor="grid-email">
                                    Email
                                </label>
                                <input className="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:border-gray-500" id="grid-email" type="email" placeholder="Email Address" name="email_address" required />
                            </div>
                            <div className="w-full lg:w-1/2 lg:px-3">
                                <label className="block tracking-wide text-gray-700 text-sm font-bold mb-2" htmlFor="grid-contact">
                                    Contact Number
                                </label>
                                <input className="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:border-gray-500" id="grid-contact" type="text" placeholder="Contact Number" name="contact_number" required />
                            </div>
                        </div>
                        <div className="flex flex-wrap mb-6">
                            <div className="w-full lg:px-3">
                                <label className="block tracking-wide text-gray-700 text-sm font-bold mb-2" htmlFor="grid-company">
                                    Company Name
                                </label>
                                <input className="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:border-gray-500" id="grid-company" type="text" placeholder="Company Name" name="company_name" required />
                            </div>
                        </div>
                        <div className="flex flex-wrap mb-6">
                            <div className="w-full lg:px-3">
                                <label className="block tracking-wide text-gray-700 text-sm font-bold mb-2" htmlFor="grid-state">
                                    What is your role?
                                </label>
                                <div className="relative">
                                    <select className="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:border-gray-500" id="grid-state" name="role" required>
                                        <option>- Select -</option>
                                        <option>CFO</option>
                                        <option>CEO</option>
                                        <option>CIO</option>
                                        <option>IT Director</option>
                                        <option>Applications Director</option>
                                        <option>Health Information Manager (HIM)</option>
                                        <option>Other</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-wrap mb-6">
                            <div className="w-full lg:px-3">
                                <label className="block tracking-wide text-gray-700 text-sm font-bold mb-2" htmlFor="grid-company">
                                    Message
                                </label>
                                <textarea rows={3} className="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:border-gray-500" id="grid-company" name="message" required placeholder="Please share any other details about how we can help." />
                            </div>
                        </div>
                        <button type="submit" className="btn btn-contained inline-block lg:ml-3" to="contact">Contact</button>
                    </form>
                </div>
            </div>
        </div>
    </>
    )
}
import "./App.css";

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Layout from "./layout/Layout";
import LandingPage from "./pages/LandingPage";
import WhatWeDo from "./pages/WhatWeDo";
import PageNotFound from "./pages/PageNotFound";
import WhoWeServe from "./pages/WhoWeServe";
import WhyVelozent from "./pages/WhyVelozent";
import WhoWeAre from "./pages/WhoWeAre";
import Contact from "./pages/Contact";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        index: true,
        element: <LandingPage />,
      },
      {
        path: "/what-we-do",
        element: <WhatWeDo />,
      },
      {
        path: "/who-we-serve",
        element: <WhoWeServe />,
      },
      {
        path: "/why-velozent",
        element: <WhyVelozent />,
      },
      {
        path: "/who-we-are",
        element: <WhoWeAre />,
      },
      {
        path: "/contact",
        element: <Contact />,
      },
    ],
  },
  {
    path: "*",
    element: <PageNotFound />,
  },
]);
function App() {
  return <RouterProvider router={router} />;
}

export default App;


import { NavLink } from "react-router-dom";

function Header({ toggle, menu }) {
    return (
        <div className="h-20 bg-shark-950 text-white">
            <div className="h-full container mx-auto">
                <div className="h-full flex flex-row items-center lg:justify-between px-4">
                    <i className="fa-solid fa-bars lg:hidden text-xl text-burnt-sienna-500 mr-4" onClick={toggle}></i>
                    <NavLink to="/">
                        <img src="/assets/images/logo.svg" alt="logo" className="h-10" />
                    </NavLink>
                    <ul className="lg:flex space-x-8 hidden">
                        {menu.map((item) => (
                            <li key={item.id} className="inline-flex" onClick={toggle}>
                                <NavLink
                                    to={item.path}
                                    className={({ isActive }) =>
                                        isActive ? "text-burnt-sienna-500" : "text-white"
                                    }
                                >
                                    {item.name}
                                </NavLink>
                            </li>
                        ))}
                    </ul>
                    <NavLink className="btn btn-contrast hidden lg:inline-block" to="contact">Get In Touch</NavLink>
                </div>
            </div>
        </div >

    )
}



export default Header
export default function AdvisoryServices() {
  return (
    <>
      <div className="bg-gray-100 py-8 lg:py-12 px-4">
        <div className="container mx-auto">
          <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-y-0 gap-y-4">
            <img
              src="/assets/images/what_we_do.png"
              alt="solutions"
              className="lg:h-[400px] mx-auto my-auto"
            />
            <div>
              <div className="pl-8 py-4 border-l border-gray-400">
                <p className="font-medium text-3xl mb-6">Advisory Services</p>
                <p className="font-medium text-xl mb-6">
                  Giving You Access to Seasoned Health IT Leaders
                </p>
                <p className="mb-2">
                  Helping You Navigate Change and Uncover Cost Savings
                </p>
                <p>
                  The majority of your IT budget and time is spent on
                  maintenance. This makes it hard to drive innovation and
                  champion initiatives that can streamline the delivery of
                  patient care. You can rely on our seasoned advisors to unlock
                  the potential, optimize inefficient processes and create a
                  roadmap to move your organization forward.
                </p>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mt-24">
            <div>
              <p className="text-xl lg:text-2xl font-medium mb-8">
                Advisory Areas
              </p>
              <p className="mb-8 text-gray-700">
                Velozent provides thought leadership to Healthcare CXOs on
                strategic planning, mergers and acquisitions, and cost
                optimization amongst others.
              </p>
              <p className="text-xl lg:text-2xl font-medium mb-8">
                Mergers & Acquisitions
              </p>
              <p className="mb-8 text-gray-700">
                Understanding how a merger or acquisition will impact your
                organization’s people, systems and processes helps you ensure a
                successful outcome and avoid common pitfalls. We help you engage
                IT stakeholders and navigate the complexity prevalent in these
                scenarios.
              </p>
              {/* <p className="mb-8 text-gray-700">
                We can conduct pre-assessment to evaluate the overall business,
                software applications, technology infrastructure, physician
                network, payor mix, contracts, and patient demographics.
              </p> */}
              <p className="mb-8 text-gray-700">
                We have proven methodologies with jump-start templates to
                accelerate the due diligence process. Our experts can provide a
                cost-benefit analysis with a go-forward plan including potential
                pitfalls, risk avoidance strategies and the right technology
                integration strategy.
              </p>
              <p className="text-xl lg:text-2xl font-medium mb-8">
                Contract and Cost Optimization
              </p>
              <p className="mb-8 text-gray-700">
                Review all cost centers, applications and vendors with
                overlapping functionalities. Velozent has proven expertise in
                reducing provider systems’ operational costs by at least 20%. We
                can help you rationalize applications, contracts, and vendors to
                eliminate redundancies and cost centers that don’t add value
              </p>
            </div>
            <img
              src="/assets/images/strategy.png"
              alt="strategy"
              className="lg:h-[400px] mx-auto"
            />
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mt-24">
            <img
              src="/assets/images/strategy.png"
              alt="strategy"
              className="lg:h-[400px] mx-auto hidden lg:block"
            />
            <div>
              {/* <p className="text-xl lg:text-2xl font-medium mb-8">
                Cloud Strategy
              </p>
              <p className="mb-8 text-gray-700">
                Migration to Cloud is one of the healthcare system CXO's top 5
                areas of concern. Understanding cost-benefits and assessment of
                risks versus rewards is critical. Velozent has vast expertise in
                on-premise deployments, hybrid deployments to migration to the
                cloud. We can assess your internal systems and recommend the
                right balance between internal and cloud systems.
              </p>
              <p className="text-xl lg:text-2xl font-medium mb-8">
                Strategic Planning & Business Continuity
              </p>
              <p className="mb-8 text-gray-700">
                Migration to Cloud is one of the healthcare system CXO's top 5
                areas of concern. Understanding cost-benefits and assessment of
                risks versus rewards is critical. Velozent has vast expertise in
                on-premise deployments, hybrid deployments to migration to the
                cloud. We can assess your internal systems and recommend the
                right balance between internal and cloud systems.
              </p> */}
              <p className="text-xl lg:text-2xl font-medium mb-8">
                System Selection and Implementation
              </p>
              <p className="mb-8 text-gray-700">
                Velozent leaders have expertise in negotiating multi-million
                dollar multi-site EMR contracts, putting together large
                implementation teams and delivering projects ahead of schedule
                and under budget. We can help you select the right system that
                meets your needs at the correct total cost of ownership.
              </p>
              <p className="text-xl lg:text-2xl font-medium mb-8">
                Cybersecurity Risk Optimization
              </p>
              <p className="mb-8 text-gray-700">
                Securing health systems and patient data is a top concern of
                health CIOs. Using the NIST Cybersecurity Framework, we take
                security assessments to the next level by mapping out practical
                recommendations, providing remediation strategies, and
                augmenting you with seasoned consultants to close the gaps in
                your network.{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-burnt-sienna-500 text-white px-4">
        <div className="container mx-auto">
          <div className="py-24">
            <p className="font-medium text-3xl mb-6 text-center">
              Recent customer success
            </p>
            <li className="py-1 flex items-start">
              <i className="fa-sharp fa-solid fa-circle-check mt-1 mr-4 text-burnt-sienna-300"></i>
              <span>
                Assisted in the acquisition of a 200+ Bed Texas hospital
                including pre-evaluation, post-acquisition cost optimization,
                and replacing legacy systems. Benefits: 21% Capex reduction.
              </span>
            </li>
            <li className="py-1 flex items-start">
              <i className="fa-sharp fa-solid fa-circle-check mt-1 mr-4 text-burnt-sienna-300"></i>
              <span>
              Helped hospital acquisitions and IT transitions for hospitals and health systems accross the United States.
              </span>
            </li>
          </div>
        </div>
      </div>
    </>
  );
}

function Mission() {
    return (
        <div className="lg:py-20 py-16 px-4">
            <div className="container mx-auto">
                <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-10 lg:justify-items-center">
                    <div className="py-16 lg:p-16 bg-white-dots-sm bg-no-repeat bg-left-top bg-10">
                        <p className="text-3xl leading-tight font-semibold">We do the<br/><span className="text-burnt-sienna-500">right things</span></p>
                        <p className="mt-8 max-w-sm text-sm text-gray-700 leading-normal">Velozent is the solution for the crossover of technology and healthcare. Implementing custom healthcare technologies, Velozent ensures medical software functions at an optimal capacity.</p>
                    </div>
                    <div className=" py-16 lg:p-16 bg-white-dots-sm bg-no-repeat bg-left-top bg-10">
                        <p className="text-3xl leading-tight font-semibold">We meet your<br/><span className="text-burnt-sienna-500">specific needs</span></p>
                        <p className="mt-8 max-w-sm text-sm text-gray-700 leading-normal">Velozent focuses on innovation and revolutionizes modern healthcare. Founded by a group of Healthcare IT leaders with decades of industry experience, Velozent is made up of a team of highly skilled individuals who have devoted their careers to deliver the best quality services with speed and efficiency.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Mission;
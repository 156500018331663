function Hero() {
    return (
        <div id="home" className="bg-shark-950 bg-none lg:bg-white-dots bg-no-repeat bg-left-top bg-contain px-4 py-10 lg:py-20">
            <div className="container mx-auto ">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 lg:px-20">
                    <div className="text-white lg:mt-16">
                        <p className="text-3xl lg:text-5xl max-w-md font-semibold">
                            Enhancing Patient Outcomes Through Healthcare IT.
                        </p>
                        <div className="pl-10 border-l border-gray-400">
                            <p className="mt-8 max-w-sm text-sm text-gray-100 leading-normal">We optimize processes and help organizations focus on initiatives that improve patient outcomes. From Managed IT services, cloud migration, cyber security to Advisory services, we help you and your patients move forward.</p>
                            <a href="#contact" className='btn btn-contained inline-block mt-10'>Get In Touch</a>
                        </div>
                    </div>
                    <div className="grid mt-8 lg:mt-0 grid-cols-2 gap-2 text-white text-sm">
                        <div>
                            <img src="/assets/images/22.png" className="w-[100%] lg:w-auto lg:h-[225px]" alt="" />
                            <p className="mt-4">Healthcare<br/>Innovation</p>
                        </div>
                        <div className="mt-8 lg:mt-16">
                            <img src="/assets/images/11.png" className="w-[100%] lg:w-auto lg:h-[300px]" alt="" />
                            <p className="mt-4">Dedication &<br/>Experience</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Hero;
import { NavLink } from "react-router-dom"

function Footer() {
    return (
        <div className='py-20 bg-shark-950 text-white px-4' id="contact">
            <div className="container mx-auto">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 items-center pb-20">
                    <div className='mx-auto'><img src="/assets/images/contact.svg" alt="logo" className="h-full" /></div>
                    <div>
                        <p className='text-5xl font-medium max-w-md leading-tight'>Let’s Innovate Together</p>
                        <p className='pt-4 max-w-md text-gray-100'>Elevate your business with end-to-end healthcare technology services</p>
                        <NavLink className='btn btn-contained inline-block mt-10' to="contact">Contact Us</NavLink>
                    </div>
                </div>
                <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 items-center py-8 border-y border-gray-600">
                    <div><img src="/assets/images/logo_white.svg" alt="logo" className="h-10" /></div>
                    <div className='font-semibold text-xl'>
                        <a href="mailto:connect@velozent.com">connect@velozent.com</a>
                    </div>
                    <div className='font-semibold text-xl'>
                        <a href="tel:+19096393504">+1 909 639 3504</a>
                    </div>
                </div>
                <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 mt-8">
                    <div>
                        <p className="text-lg">Modern Healthcare<br />Transformation & Innovation</p>
                        <p className="pt-6 text-lg">UCI Beall Applied Innovations <br />
                            5270 California Ave #100 <br />
                            Irvine, CA, 92679</p>
                    </div>
                    <div>
                        <div className="pb-4 text-xl font-semibold">Services</div>
                        <ul>
                            <li className="py-1"><NavLink to="what-we-do">Healthcare IT Services</NavLink></li>
                            <li className="py-1"><NavLink to="what-we-do">Cyber Security Solutions</NavLink></li>
                            <li className="py-1"><NavLink to="what-we-do">Cloud Services</NavLink></li>
                            <li className="py-1"><NavLink to="what-we-do">Advisory Services</NavLink></li>
                        </ul>
                    </div>
                    <div>
                        <div className="pb-4 text-xl font-semibold">Useful links</div>
                        <ul>
                            <li className="py-1"><NavLink to="what-we-do">What We Do</NavLink></li>
                            <li className="py-1"><NavLink to="who-we-serve">Who We Serve</NavLink></li>
                            <li className="py-1"><NavLink to="why-velozent">Why Velozent</NavLink></li>
                            <li className="py-1"><NavLink to="who-we-are">Who We are</NavLink></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;
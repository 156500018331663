import { useState } from "react"
import ServiceCard from "./ServiceCard"
import { NavLink } from "react-router-dom"

export default function Solutions() {
    const [items, setItems] = useState([{
        id: 1,
        title: "Healthcare IT Services",
        icon: "heartbeat.svg",
        active: true,
        url:'what-we-do',
        description: 'Leverage technology to enhance medical processes, streamline operations, and improve patient care in the ever-evolving landscape of healthcare.',
        items: ['Managed Services - OnSite/Offsite', 'Applications Implementation', 'Applications Support – Onsite/Offsite', 'Infrastructure Support (Hardware, Software, Network, Storage, Security, Servers, Wireless)']
    },
    {
        id: 2,
        title: "Cyber Security Solutions",
        icon: "security.svg",
        active: false,
        url:'what-we-do',
        description: 'Safeguard sensitive patient data and critical medical infrastructure, ensuring the confidentiality, integrity, and availability of healthcare information systems.',
        items: ['Enterprise Security Assessment', 'Cyber Defense', 'Security Monitoring Services', 'Remediation Services', 'Managed Security Services', 'Cloud Security']
    },
    {
        id: 3,
        title: "Cloud Services",
        icon: "cloud.svg",
        active: false,
        url:'what-we-do',
        description: 'Provide secure and scalable platforms, enabling efficient data storage, collaboration, and access to medical information across the healthcare ecosystem.',
        items: ['Cloud Strategy', 'Cloud Advisory & Migration Services', 'Cloud PaaS & SaaS Services']
    },
    {
        id: 4,
        title: "Advisory Services",
        icon: "chat.svg",
        active: false,
        url:'what-we-do',
        description: "Offer strategic guidance and expertise to optimize healthcare organizations' operations, improve patient outcomes, and navigate industry complexities.",
        items: ['Mergers and Acquisitions', 'Contract and Cost Optimization', 'Cloud Strategy', 'Strategic Planning & Business Continuity', 'System Selection & Implementation']
    }])

    const [currentIndex, setCurrentIndex] = useState(0)
    const [activeItem, setActiveItem] = useState(items[currentIndex])
    const setCardActive = (id) => {
        setItems((prevItems) => {
            let index = prevItems.findIndex((obj) => {
                return obj.active === true
            })
            let newIndex = prevItems.findIndex((obj) => {
                return obj.id === id
            })
            prevItems[index].active = false
            prevItems[newIndex].active = true
            return [...prevItems]
        })
        let newIndex = items.findIndex((obj) => {
            return obj.id === id
        })
        setActiveItem(items[newIndex])
        setCurrentIndex(newIndex)
    }
    const prev = () => {
        if (currentIndex > 0) {
            setCurrentIndex((prevIndex) => {
                setCardActive(items[prevIndex - 1].id)
                return prevIndex - 1
            })

        }
    }
    const next = () => {
        if (currentIndex < (items.length - 1)) {
            setCurrentIndex((prevIndex) => {
                setCardActive(items[prevIndex + 1].id)
                return prevIndex + 1
            })
        }
    }
    return (
        <>
            <div className="bg-white py-16 lg:py-20 px-4">
                <div className="container mx-auto">
                    <p className="text-center font-medium text-3xl mb-4">Empowering Healthcare IT</p>
                    <p className="mx-auto lg:max-w-3xl text-center mb-20">Our specialized health IT services and experts can help you tackle the toughest IT challenges, streamline IT operations, and augment with the right technical talent.</p>
                    <div className="flex flex-row flex-wrap max-w-none lg:max-w-[70%] lg:mx-auto justify-around space-y-2 lg:space-y-0">
                        {
                            items.map((item) => (
                                <ServiceCard key={item.id} icon={item.icon} title={item.title} active={item.active} onClickCard={() => setCardActive(item.id)} />
                            ))
                        }
                    </div>
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mt-20">
                        <div className="px-4 py-4 lg:ml-24 min-h-[500px]">
                            <div className="flex items-center space-x-6 mb-10 text-burnt-sienna-500">
                                <i className="fa-solid fa-arrow-left cursor-pointer" onClick={prev}></i>
                                <i className="fa-solid fa-arrow-right cursor-pointer" onClick={next}></i>
                            </div>
                            <p className='max-w-md text-3xl font-medium mb-2'>{activeItem.title}</p>
                            <p className='max-w-md text-burnt-sienna-500 mb-6'>{activeItem.description}</p>
                            <ul>
                                {
                                    activeItem.items.map((item, index) => (
                                        <li className="py-1 flex items-start" key={index}>
                                            <i className="fa-sharp fa-solid fa-circle-check mt-1 mr-4 text-burnt-sienna-500"></i>
                                            <span>{item}</span></li>
                                    ))
                                }
                            </ul>
                            <NavLink className='btn btn-contained inline-block mt-10' to={activeItem.url}>Learn More</NavLink>
                        </div>
                        <img src="/assets/images/what_we_do.png" alt="what_we_do" className="lg:h-[500px] mx-auto"/>
                    </div>
                </div>
            </div>
            <div className="bg-white bg-none lg:bg-gray-dots bg-no-repeat bg-left-top bg-contain px-4 py-10 lg:py-20">
                <div className="container mx-auto ">
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 lg:px-20">
                        <div className="lg:mt-20">
                            <p className="text-2xl lg:text-4xl max-w-md font-medium">
                                We Have Vast Health IT Systems Expertise.
                            </p>
                            <p className="lg:ml-16 md-6 lg:mt-12 text-sm leading-normal lg:absolute lg:max-w-xl">We are a unique Health IT Service Provider that live and breathe healthcare. We are thought leaders with
                                decades of experience in running health systems while maintaining the foresight on innovative technologies and emerging in health care.</p>
                        </div>
                        <div className="grid mt-8 lg:mt-0 lg:grid-rows-2 lg:grid-cols-2 text-sm gap-6">
                            <div className="lg:row-span-2">
                                <div className="bg-gray-100 p-6 rounded-lg">
                                    <p className="text-burnt-sienna-500 font-semibold text-xl">01</p>
                                    <p className="py-2 font-semibold text-xl">EMR</p>
                                    <p>Epic, Meditech, Cerner, Allscripts and others</p>
                                </div>
                            </div>
                            <div className="p-6 rounded-lg bg-gray-100">
                                <p className="text-burnt-sienna-500 font-semibold text-xl">02</p>
                                <p className="py-2 font-semibold text-xl">ERP</p>
                                <p>Lawson, Workday, UKG and others</p>
                            </div>
                            <div className="p-6 rounded-lg bg-gray-100">
                                <p className="text-burnt-sienna-500 font-semibold text-xl">03</p>
                                <p className="py-2 font-semibold text-xl">Cloud Services</p>
                                <p>AWS, Microsoft Azure, Salesforce and others</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";

export default function PageNotFound() {
    return (<>
        <Helmet>
            <title>Velozent | 404</title>
        </Helmet>
        <div className="h-screen w-screen flex justify-center items-center">
            <div className="p-8 text-center">
                <p className="text-2xl font-medium">Oops! the page you are looking for has not been found</p>
                <Link to="/">
                    <button className='btn btn-contained inline-block mt-6'>Go to Home</button>
                </Link>
            </div>
        </div>
    </>)

}
import { Outlet } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import SideNavbar from "./SideNavbar";
import { useState } from "react";
import ScrollToTop from "../sections/ScrollToTop";

function Layout() {
    const [isOpen, setIsOpen] = useState(false);
    const menu = [{
        id: 1,
        name: 'What we do',
        path: 'what-we-do'
    },
    {
        id: 2,
        name: 'Who we serve',
        path: 'who-we-serve'
    },
    {
        id: 3,
        name: 'Why Velozent',
        path: 'why-velozent'
    },
    {
        id: 4,
        name: 'Who we are',
        path: 'who-we-are'
    }]


    const toggleSideNav = () => {
        setIsOpen(!isOpen)
    }
    return (<>
        <ScrollToTop/>
        <Header toggle={toggleSideNav} menu={menu}/>
        <SideNavbar isOpen={isOpen} menu={menu} onCloseSideNav={()=>setIsOpen(false)}/>
        <Outlet />
        <Footer />
    </>)
}

export default Layout;
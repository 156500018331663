import { ReactSVG } from 'react-svg'

export default function ServiceCard({ title, icon, active, onClickCard }) {
    return (
        <div onClick={onClickCard} className={`flex flex-col items-start p-4 lg:p-8 w-[45%] lg:w-[200px] rounded-lg shadow-xl hover:cursor-pointer ${active ? 'bg-burnt-sienna-400 text-white' : ''}`}>
            <ReactSVG className={`mb-8 h-8 ${active ? 'text-white' :'text-burnt-sienna-500 hover:text-white'}`} src={`/assets/images/${icon}`}/>
            <p className="text-l font-medium mb-10">{title}</p>
            <i className="mt-auto fa-sharp fa-solid fa-chevron-down"></i>
        </div>
    )
}
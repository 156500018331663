import Statscard from "./StatsCard";
import Techcard from "./TechCard";

function Stats() {
    const stats = [{
        id: 1,
        title: "12",
        description: "Successful years"
    },
    {
        id: 2,
        title: "50K",
        description: "Customers"
    },
    {
        id: 3,
        title: "95%",
        description: "Greater efficiency"
    }]
    const technologies = [{
        id: 1,
        title: "Artificial Intelligence / Machine Learning",
        description: "Algorithms to predict and prevent patient readmission, Sepsis, hospital acquired conditions",
        highlight: true,
        icon: 'brain.svg'
    },
    {
        id: 2,
        title: "Robotic Process Automation",
        description: "To optimize Remit Processing, Cash Posting, Denials Appeals.",
        highlight: false,
        icon: 'circuitry.svg'
    },
    {
        id: 3,
        title: "Chat Bots",
        description: "For Patient and Employee Engagement reducing carbon foot print",
        highlight: false,
        icon: 'robot.svg'
    }, {
        id: 4,
        title: "Predictive Analytics",
        description: "For early diagnosis of chest ailments to serve medically under-served areas across the world",
        highlight: true,
        icon: 'batch_prediction.svg'
    }
    ]
    return (
        <div className="bg-orange-100 py-20 px-4">
            <div className="container mx-auto">
                <img src="/assets/images/logo_icon.svg" alt="logo" className="mx-auto h-12" />
                {/* <p className='lg:max-w-md my-4 text-3xl font-semibold text-center mx-auto'>Trust us we are <span className='text-burnt-sienna-500'>experts</span> in Healthcare</p>
                <p className='text-center lg:max-w-xl mx-auto mb-10 lg:mb-20'>Rely on our expertise in healthcare for comprehensive solutions that prioritize patient well-being, operational efficiency, and industry compliance.</p>
                <div className="grid grid-cols-1 lg:grid-cols-3 lg:gap-x-16 gap-y-2 lg:gap-y-0 lg:mt-10 xl:mx-32">
                    {stats.map((stat) =>
                        (<Statscard key={stat.id} title={stat.title} description={stat.description}/>)
                    )
                    }
                </div> */}
                <p className='lg:max-w-md lg:mt-4 text-3xl font-semibold text-center mx-auto'>New age technologies</p>
                <p className='text-center mt-6 mb-20 lg:max-w-xl mx-auto'>We collaborate with innovative health tech companies that aspire to make a difference in our quality of life.</p>
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-y-4 lg:gap-16 my-10 xl:mx-32">
                    {technologies.map((stat) =>
                        (<Techcard key={stat.id} icon={stat.icon} title={stat.title} description={stat.description} highlight={stat.highlight} />)
                    )
                    }
                </div>
            </div>
        </div>
    )
}

export default Stats;
export default function CyberSecurity() {
    const items = ['Web-Security',
        'Mobile (Android & IOS) Security',
        'Cloud Security',
        'Protocol Security',
        'Product Security',
        'Network Security',
        'Wireless Security',
        'Red Teaming',
        'Forensic Audit (DFIR)']

    const pulse = ['IoT Real-Time Discovery & Inventory',
        'IoT Automated Inventory & Cost Analysis',
        'Device Risk Score',
        'Time Cost Savings – Device Location Service',
        'Device Behavior Analytics',
        'Tagging; Inventory, Risk, EOS/EOL Lifecycle',
        'IoT Threat Monitoring, Detection & Response',
        'Geolocation',
        'East-West Device Traffic & Threats',
        'IR Response & Investigation',
        'Security Event Management & Response Events',
        'Risk Score & Remediation Actions',
        'Full Cycle Device Risk Remediation Tracking, Governance & Compliance',]
    return (
        <>
            <div className="bg-gray-100 py-8 lg:pt-12 lg:pb-24 px-4">
                <div className="container mx-auto">
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-y-4 lg:gap-y-0">
                        <img
                            src="/assets/images/what_we_do.png"
                            alt="solutions"
                            className="lg:h-[400px] mx-auto my-auto"
                        />
                        <div>
                            <div className="pl-8 py-4 border-l border-gray-400">
                                <p className="font-medium text-3xl mb-6">Cyber Security Services</p>
                                <p>
                                Guardians of Digital Health: Our cybersecurity consulting shields your healthcare data with a prescription for impenetrable protection. Where Health meets Fortitude, we're the prescription for cybersecurity confidence in the ever-evolving world of healthcare technology.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-burnt-sienna-500 text-white py-24 px-4">
                <div className="container mx-auto">
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-20">
                        <div>
                            <p className="font-semibold text-2xl mb-6">Security Assessment</p>
                            <div className="pl-8 py-2 border-l border-gray-300">
                                <p className="mb-4">Security is foundational to Velozent. We provides a myriad of services that enable healthcare providers to effectively secure all their medical devices and Internet of Medical Things (IoMT).
                                </p>
                                <p>Velozent Cyber Security services help you assess risk and proactively address all facets of your security environment, from threat intelligence to compliance. We leverage proven methodologies, intelligent automation and industry-leading partners to tailor security solutions to your health system needs.</p>
                            </div>
                        </div>
                        <div className="mt-16">
                            <p className="text-2xl font-semibold">Assessment services</p>
                            <ul className="divide-y divide-gray-300 mt-4">
                                {items.map((item, index) => (
                                    <li className="py-2" key={index}>{item}</li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-white py-24 px-4">
                <div className="container mx-auto">
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-20">
                        <div>
                            <p className="font-semibold text-2xl mb-6">Cyber Defense</p>
                            <p>As the threat landscape continues to expand, health systems are challenged to build resilient cyber defenses and maintain the skills needed to manage advanced monitoring tools and a growing volume of events.</p>
                        </div>
                        <div className="mt-16">
                            <p>Velozent Cyber Defense services can help. We partnered with Culinda, an end-to-end Healthcare Cyber security company to provide instant insights, data-driven analytics, and security intelligence that enables real-time defenses.</p>
                            <p className="mt-6">Velozent experts can monitor your security operations centers delivering proactive security event and incident detection, rapid incident response and recovery management to minimize breaches and disruptions.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="py-24 bg-orange-100 px-4">
                <div className="container mx-auto">
                <p className="text-3xl font-semibold text-center mb-6">Gain Visibility and Protect Everything on the Network</p>
                <p className="text-2xl font-light mb-16 lg:max-w-[60%] text-center lg:mx-auto">Velozent, in partnership with Culinda, provides a Realtime and Agentless 24×7 network monitoring service.</p>
                <p className="text-3xl font-semibold text-center">Culinda <span className="text-burnt-sienna-500">Pulse</span></p>
                <img
                            src="/assets/images/pulse.png"
                            alt="pulse"
                            className="my-20 mx-auto"
                        />
                    {
                        <ul className="grid grid-cols-1 lg:grid-cols-2 gap-x-4 lg:max-w-[70%] lg:mx-auto">
                            {pulse.map((obj, index) => (
                                <li className="py-2 inline-flex items-start" key={index}>
                                    <i className="fa-sharp fa-solid fa-circle-check mt-1 mr-4 text-burnt-sienna-500"></i>
                                    <span>{obj}</span>
                                </li>
                            ))}
                        </ul>
                    }
                </div>
            </div>
        </>
    );
}

import { useState } from "react";

export default function CloudServices() {
  const customers = [
    {
      id: 1,
      title: "Health Systems Data Archival",
      items: [
        "Archived EMR, ERP, other clinical systems for a large health system",
        "Migrated decades of sensitive data from legacy systems to cloud archival system and integrated it with current EMR and other IT systems for easy access and retrieval",
        "Benefits: Cost savings gained from eliminating infrastructure support and maintenance costs while conforming to regulatory processes",
      ],
    },
    {
      id: 2,
      title: "Disaster Recovery Project",
      items: [
        "Migrated millions of old PACS images spanning nearly twenty years of data to MZ Azure Cloud",
        "Benefits: Cost savings gained from eliminating infrastructure support and maintenance costs, regulatory compliance and disaster recovery",
      ],
    },
  ];
  const [tabIndex, setTabIndex] = useState(1);

  return (
    <>
      <div className="bg-gray-100 py-8 lg:py-12 px-4">
        <div className="container mx-auto">
          <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-y-0 gap-y-4">
            <img
              src="/assets/images/what_we_do.png"
              alt="solutions"
              className="lg:h-[400px] mx-auto my-auto"
            />
            <div>
              <div className="pl-8 py-4 border-l border-gray-400">
                <p className="font-medium text-3xl mb-6">Cloud Services</p>
                <p className="font-medium text-xl mb-6">
                  Transform Your Healthcare Business With Agile Cloud Services
                </p>
                <p>
                  Make the right technology investments at the right time and on
                  the right platforms to drive innovation, improve health
                  outcomes and increase patient satisfaction
                </p>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mt-24">
            <div>
              <p className="text-xl lg:text-2xl font-medium mb-8">
                A World Leading Digital Solution Provider
              </p>
              <p className="mb-8 text-gray-700">
                When health systems do the cloud right, it can provide up to
                three times the return on investment and faster business results
                with less cost, risk and disruption. Velozent helps you make the
                right decisions about what applications to migrate to the cloud
                and when with the right cost-benefit analysis. With Velozent
                Cloud services, you can maximize your use of data and ensure
                your environment remains secure.
              </p>
              {/* <p className="mb-8 text-gray-700">
                We understand the sensitivity amongst health systems to migrate
                to the cloud. We take a balanced approach and work alongside
                client executives keeping in view their strategic objectives. We
                assisted various health systems in migrating EMR, ERP, Imaging,
                Lab, and Patient Engagement systems to private, hybrid, and
                public clouds. Let us help you define, execute and manage your
                cloud strategy.
              </p> */}
              <p className="text-xl lg:text-2xl font-medium mb-8">
                Cloud Advisory and Migration Services
              </p>
              <p className="mb-8 text-gray-700">
                The healthcare industry is evolving at a rapid pace and is
                embracing technology evolution at a faster pace than earlier. It
                is vital for Healthcare CIOs to envision the target state for
                their IT ecosystem but may not necessarily know how to get
                there.
              </p>
              <p className="mb-8 text-gray-700">
                Velozent understands how to prioritize the IT environment to
                meet your health system goals. We have the track record, skills
                and experience to help you avoid costly mistakes.
              </p>
              <p className="text-xl lg:text-2xl font-medium mb-8">
                PaaS and SaaS Services
              </p>
              {/* <p className="mb-8 text-gray-700">
                Velozent understands how to prioritize the IT environment to
                meet your health system goals. We have the track record, skills
                and experience to help you avoid costly mistakes.
              </p> */}
              <p className="mb-8 text-gray-700">
                Velozent professionals have profound expertise in numerous
                Platform as a Service (PaaS) and Software as a Service (SaaS)
                applications, such as AWS, Azure, Oracle Cloud, SalesForce,
                WorkDay, UKG, ServiceNow to name a few. Our certified
                professionals bring deep expertise and proven processes to your
                cloud strategy and migration to modernize on your schedule with
                minimal business disruption.
              </p>
            </div>
            <img
              src="/assets/images/strategy.png"
              alt="strategy"
              className="lg:h-[400px] mx-auto"
            />
          </div>
        </div>
      </div>
      <div className="bg-burnt-sienna-500 text-white px-4">
        <div className="container mx-auto">
          <div className="py-24">
            <p className="font-medium text-3xl mb-6 text-center">
              Recent customer success
            </p>
            <div className="px-8 py-10 min-h-[250px]">
              <p className="text-2xl mb-4 font-semibold">
                {customers[tabIndex - 1].title}
              </p>
              <ul>
                {customers[tabIndex - 1].items.map((item, index) => (
                  <li className="py-1 flex items-start" key={index}>
                    <i className="fa-sharp fa-solid fa-circle-check mt-1 mr-4 text-burnt-sienna-300"></i>
                    <span>{item}</span>
                  </li>
                ))}
              </ul>
            </div>
            <ul className="flex space-x-4 justify-center mt-16">
              {customers.map((item) => (
                <li
                  key={item.id}
                  className={`rounded-sm p-1 cursor-pointer w-6 text-sm ${
                    item.id === tabIndex ? " bg-white" : "bg-gray-300"
                  }`}
                  onClick={() => setTabIndex(item.id)}
                ></li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

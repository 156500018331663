import { NavLink } from "react-router-dom";

export default function SideNavbar({ isOpen, menu, onCloseSideNav }) {
    return (
        <>
            <div className={`lg:hidden px-4 py-6 h-screen bg-white fixed top-0 w-screen ${isOpen ? "z-10 left-0" : 'left-[-100%]'}`}>
                <div className="flex justify-between items-center">
                    <NavLink to="/" onClick={onCloseSideNav} >
                        <img src="/assets/images/logo.svg" alt="logo" className="h-10" />
                    </NavLink>
                    <i className="text-2xl fa-solid fa-xmark" onClick={onCloseSideNav}></i>
                </div>
                <ul className="flex flex-col py-8 space-y-2">
                    {menu.map((item) => (
                        <li key={item.id} className="inline-block">
                            <NavLink
                                onClick={onCloseSideNav}
                                to={item.path}
                                className={({ isActive }) =>
                                    isActive ? "text-burnt-sienna-500 font-semibold" : "text-burnt-sienna-500"
                                }
                            >
                                {item.name}
                            </NavLink>
                        </li>
                    ))}
                </ul>
            </div>
        </>
    )
}
export default function About() {
    return (
        <div className="bg-shark-950 bg-none lg:bg-waves bg-no-repeat bg-left-top bg-50% px-4 py-16 lg:py-20 text-white">
            <div className="container mx-auto">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                    <div className="lg:mt-16">
                        <p className="text-2xl lg:text-4xl max-w-md font-medium">
                            A World Leading Digital Solution Provider
                        </p>
                        <div className="lg:ml-16 lg:mt-12 ">
                            <p className="text-sm leading-normal lg:max-w-xl">By implementing efficiency strategies that have been tested and proven in hundreds of hospitals and ambulatory care facilities across the nation, we can help any healthcare organization decrease operational expenses by 20 to 30 percent.</p>
                            <div className="divide-y divide-gray-600 mt-8">
                                <div className="py-6">
                                    <p className="mb-2 text-xl"><span className="mr-2 italic">01</span> Healthcare strategy</p>
                                    <p className="text-sm lg:max-w-sm">By implementing efficiency strategies that have been tested and proven in.</p>
                                </div>
                                <div className="py-6">
                                    <p className="mb-2 text-xl"><span className="mr-2 italic">02</span> Healthcare solution</p>
                                    <p className="text-sm lg:max-w-sm">By implementing efficiency strategies that have been tested and proven in.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <img src="/assets/images/strategy.png" alt="strategy" className="lg:h-[400px] mx-auto my-auto"/>
                </div>
            </div>
        </div>
    )
}
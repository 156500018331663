import { Helmet } from "react-helmet-async";

export default function WhyVelozent() {
    return (<>
        <Helmet>
            <title>Why Velozent | Velozent</title>
        </Helmet>
        <div className="bg-shark-950 text-white py-16 lg:py-20 px-4 bg-none lg:bg-white-dots-reverse bg-no-repeat bg-right-top-56">
            <div className="container mx-auto">
                <p className="font-bold text-3xl lg:text-5xl mb-20 text-center">Why Velozent</p>
                <div className="grid lg:grid-cols-2 gap-y-8 lg:gap-y-0 lg:px-10">
                    <div className="lg:max-w-lg pl-8 py-4 border-l border-gray-400">
                        <p className="font-medium text-2xl lg:text-3xl mb-6">Team of Experts For Your Health Innovation Ideas</p>
                        <ul>
                            <li className="py-1 flex items-start">
                                <i className="fa-sharp fa-solid fa-circle-check mt-1 mr-4 text-burnt-sienna-300"></i>
                                <span>We can be your one stop shop for all your health IT needs.</span></li>
                            <li className="py-1 flex items-start">
                                <i className="fa-sharp fa-solid fa-circle-check mt-1 mr-4 text-burnt-sienna-300"></i>
                                <span>Our clients receive round-the-clock care and we do not compromise on this.</span></li>
                            <li className="py-1 flex items-start">
                                <i className="fa-sharp fa-solid fa-circle-check mt-1 mr-4 text-burnt-sienna-300"></i>
                                <span>We have a single-minded focus on healthcare IT.</span></li>
                            <li className="py-1 flex items-start">
                                <i className="fa-sharp fa-solid fa-circle-check mt-1 mr-4 text-burnt-sienna-300"></i>
                                <span>Our portfolio of services ranges from applications, infrastructure, security, cloud to managed services.</span></li>
                            <li className="py-1 flex items-start">
                                <i className="fa-sharp fa-solid fa-circle-check mt-1 mr-4 text-burnt-sienna-300"></i>
                                <span>We have the expertise, and resources to serve any of your needs.</span></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div className="bg-white py-16 lg:py-20 px-4">
            <div className="container mx-auto">
                <p className="text-center font-medium text-3xl mb-16">We Are Dedicated to Shape Perfect Solutions</p>
                <div className="lg:max-w-[70%] lg:mx-auto space-y-8">
                    <div className="flex items-start">
                        <i className="mt-1 fa-solid fa-circle-question text-burnt-sienna-500"></i>
                        <div className="ml-4">
                            <p className="mb-2 font-semibold text-burnt-sienna-500">Why is Velozent the best choice for you?</p>
                            <p>Experience specifically in the HEALTHCARE SPACE. We are a dedicated team comprised exclusively of healthcare IT professionals. This sets us apart from our competitors because most IT Service providers are generalists and do not have the familiarity with healthcare infrastructure and operations as we do.</p>
                        </div>
                    </div>
                    <div className="flex items-start">
                        <i className="mt-1 fa-solid fa-circle-question text-burnt-sienna-500"></i>
                        <div className="ml-4">
                            <p className="mb-2 font-semibold text-burnt-sienna-500">How can we benefit from your service?</p>
                            <p>The years of service and hundreds of hospitals and clinics that we have built up from scratch or transformed from archaic and obsolete to modern and bleeding edge are what we leverage to make massive improvements as well as bringing operational efficiency including a markedly improved revenue cycle for our clients. Test us! We will prove it.</p>
                        </div>
                    </div>
                    <div className="flex items-start">
                        <i className="mt-1 fa-solid fa-circle-question text-burnt-sienna-500"></i>
                        <div className="ml-4">
                            <p className="mb-2 font-semibold text-burnt-sienna-500">Why is Velozent so popular among all other healthcare IT solution providers?</p>
                            <p>We run a lean and proactive team of seasoned healthcare professionals, computer scientists, engineers, developers and support staff that move quickly throughout any clinical operation to assess, call out gaps as well as risks and make recommendations solely for the benefit of the client. We are not beholden to any vendor nor are we salesmen in IT coats. Our goal is always to improve operational efficiency through advanced technology and communication.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>)

}
import { Helmet } from "react-helmet-async";
import { ReactSVG } from 'react-svg'
import HealthCare from "../sections/what-we-do/Healthcare";
import { AdvisoryServices, CloudServices, CyberSecurity } from "../sections/what-we-do";
import { useState } from "react";

export default function WhatWeDo() {
    const items = [{
        id: 1,
        title: "Healthcare IT Services",
        icon: "heartbeat.svg"
    },
    {
        id: 2,
        title: "Cyber Security Solutions",
        icon: "security.svg"
    },
    {
        id: 3,
        title: "Cloud Services",
        icon: "cloud.svg"
    },
    {
        id: 4,
        title: "Advisory Services",
        icon: "chat.svg"
    }]
    const [selectedIndex, setSelectedIndex] = useState(1)
    return (<>
        <Helmet>
            <title>What We Do | Velozent</title>
        </Helmet>
        <div className="bg-shark-950 text-white py-16 lg:py-20 px-4 bg-none lg:bg-white-dots-reverse bg-no-repeat bg-right-top-56">
            <div className="container mx-auto">
                <p className="font-bold text-3xl lg:text-5xl mb-20 text-center">What we do</p>
                <div className="grid lg:grid-cols-2 gap-y-8 lg:gap-y-0 lg:px-10">
                    <div className="lg:max-w-lg pl-8 py-4 border-l border-gray-400">
                        <p className="font-medium text-2xl lg:text-3xl mb-6">Specialized Health IT Services That Reduce Costs and Streamline Processes</p>
                        <p className="text-gray-300">We are a unique Health IT Service Provider that lives and breathes healthcare. We are thought leaders with decades of experience in running health systems while maintaining the foresight on innovative technologies and emerging in health care. We act as an extension of your team to provide health IT capabilities that reduce operating costs, improve productivity, and improve patient outcomes.</p>
                        <a href="#contact" className='btn btn-contained inline-block mt-10'>Get In Touch</a>
                    </div>
                    <div className="flex flex-col lg:justify-end lg:px-16">
                        <ul className="divide-y divide-gray-600">
                            {
                                items.map((item) => (
                                    <li className="py-2 flex items-center" key={item.id}>
                                        <ReactSVG className="h-8 mr-2 text-white" src={`/assets/images/${item.icon}`} />
                                        <span>{item.title}</span>
                                    </li>))
                            }
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div className="bg-gray-100 px-4">
            <div className="container mx-auto">
                <ul className="flex lg:flex-row flex-col space-y-4 lg:space-y-0 lg:space-x-4 lg:justify-center py-16">
                    {
                        items.map((item) => (
                            <div key={item.id} onClick={() => setSelectedIndex(item.id)} className={`rounded cursor-pointer border border-burnt-sienna-500 font-semibold py-2 px-6 text-sm flex items-center ${item.id === selectedIndex ? "text-white bg-burnt-sienna-500" : "text-burnt-sienna-500"}`}>{item.title}</div>
                        ))
                    }
                </ul>
            </div>
        </div>
        {selectedIndex === 1 && <HealthCare />}
        {selectedIndex === 2 && <CyberSecurity />}
        {selectedIndex === 3 && <CloudServices />}
        {selectedIndex === 4 && <AdvisoryServices />}
    </>)

}
import { Helmet } from "react-helmet-async";
import Services from "../sections/Services";
import Mission from "../sections/Mission";
import Hero from "../sections/Hero";
import { Stats } from "../sections/stats";
import { Solutions } from "../sections/solutions";
import About from "../sections/About";

function LandingPage() {
    return (<>
        <Helmet>
            <title>Home | Velozent</title>
        </Helmet>
        <Hero/>
        <Solutions/>
        <Stats/>
        <About/>
        <Mission/>
        <Services/>
    </>)

}

export default LandingPage;
import { Helmet } from "react-helmet-async";

export default function WhoWeServe() {
    return (<>
        <Helmet>
            <title>Who We Serve | Velozent</title>
        </Helmet>
        <div className="bg-shark-950 text-white py-16 lg:py-20 px-4 bg-none lg:bg-white-dots-reverse bg-no-repeat bg-right-top-56">
            <div className="container mx-auto">
                <p className="font-bold text-3xl lg:text-5xl mb-20 text-center">Who We Serve</p>
                <div className="grid lg:grid-rows-2 gap-y-8 lg:gap-y-0 lg:px-10">
                    <div className="lg:max-w-lg pl-8 py-4 border-l border-gray-400">
                        <p className="font-medium text-2xl lg:text-3xl mb-6">Inspired IT Solutions for Every Healthcare Provider</p>
                        <p className="text-gray-300">Velozent is a national leader that provides comprehensive healthcare IT services for health systems, physician networks, managed service organizations, home health companies, and behavioral health providers</p>
                    </div>
                    <div className="lg:max-w-lg pl-8 py-4 border-l border-gray-400 lg:ml-auto">
                        <p className="font-medium text-2xl lg:text-3xl mb-6">All In One</p>
                        <p className="text-gray-300">Our leadership team is comprised of CIOs, CISOs and CTOs who have devoted their careers to the intersection of healthcare and technology. Velozent's headquarters is in Southern California however our resources are strategically located across the nation. Frequently, our clients require round-the-clock attention so having team members nearby is a great advantage.</p>
                    </div>
                </div>
            </div>
        </div>
        <div className="bg-white py-16 lg:py-20 px-4">
            <div className="container mx-auto">
                <p className="font-semibold text-burnt-sienna-500 mb-4">Case Studies</p>
                <div className="grid grid-cols-1 lg:grid-cols-2 lg:space-x-10 lg:mb-40">
                    <div className="mb-10 lg:mb-0">
                        <p className="text-2xl font-medium">Velozent Revives Struggling Health System with M&A Expertise</p>
                        <div className="section">
                            <p className="font-semibold mt-8 mb-4">Challenge</p>
                            <p>A healthcare investor group was exploring to acquire a mid-west health system that was losing money and faced closure within weeks. The investor group was comprised of legal and financial members but needed a seasoned healthcare team for due diligence, transition, and post-acquisition.</p>
                        </div>
                        <div className="section">
                            <p className="font-semibold mt-8 mb-4">Solution</p>
                            <p>Within a day of receiving the CEO's call, the Velozent team arrived on the ground and quickly assessed the situation. Velozent put together a "best-in-class" multi-disciplinary team with deep expertise in health care, cyber security and health IT and a portfolio of technology systems. Led by a CXO, Velozent set up a 24/7 command center coordinating disparate efforts of various internal teams and vendors. With the goal of restoring the hospital for some normalcy, the Velozent team worked round the clock to isolate the root cause and affected systems, install new cyber security monitoring and threat prevention systems, secure a new digital perimeter with clean operational systems, and got the hospital up and running within seven (7) days.</p></div>
                        <div className="section">
                            <p className="font-semibold mt-8 mb-4">Results:</p>
                            <p>The health system was operational within seven days of engaging Velozent and saved millions of dollars in potential ransomware payments. Patients' confidence was restored and put the Hospital back on a path of normalcy.</p>
                        </div>
                    </div>
                    <div className="flex bg-cover lg:bg-contain bg-no-repeat pt-[25%] lg:pt-[32%] lg:h-[455px] bg-case-study-one">
                        <div className="p-4 lg:p-8 text-xs lg:text-base lg:max-w-md text-gray-400">
                            <p className="mb-2">"Velozent has a rare combination of health care operational processes, health care systems, and IT security. There’s no other team that we know of who could have resolved our security issue in such a record time at an affordable price point"</p>
                            <p className="font-semibold">CEO, 300-bed Acute Care Health System</p>
                        </div>
                    </div>
                </div>
                <div className="grid grid-cols-1 lg:grid-cols-2 lg:space-x-10 mt-16 lg:mt-0">
                    <div className="flex bg-cover mt-10 lg:mt-0 lg:bg-contain bg-no-repeat pt-[25%] lg:pt-[32%] lg:h-[455px] bg-case-study-two lg:order-1 order-2">
                        <div className="p-4 lg:p-8 text-xs lg:text-base lg:max-w-md text-gray-400">
                            <p className="mb-2">"Velozent’s health care M&A play book was critical to our turn around strategy. They bring a rare expertise blending deep health care operational knowledge and health technology systems."</p>
                            <p className="font-semibold">CEO, 300-bed Acute Care Health System</p>
                        </div>
                    </div>
                    <div className="lg:order-2 order-1">
                        <p className="text-2xl font-medium">Restoring Acute Care Health System from a ransomware attack</p>
                        <div className="section">
                            <p className="font-semibold mt-8 mb-4">Challenge</p>
                            <p>A 300-bed Northern California Health system was facing a crisis due to a ransomware attack. All IT systems had to be taken down, and the hospital resorted to paper processes, an untenable situation. The hospital pulled together a team to resolve the issue, however, it was not getting results. The hospital was losing millions of dollars a day. Amidst mounting losses and public outcry, Hospital CEO reached out to Velozent.</p>
                        </div>
                        <div className="section">
                            <p className="font-semibold mt-8 mb-4">Solution</p>
                            <div>
                                <p>Velozent assembled a multi-disciplinary team of experts in healthcare mergers & acquisitions, health systems operational processes, and healthcare technology. Leveraging its proven M&A playbook, Velozent delivered the following:</p>
                                <ul className="space-y-2 mt-8">
                                    <li className="flex items-start">
                                        <i className="fa-sharp fa-solid fa-circle-check mr-4 mt-1 text-burnt-sienna-500"></i>
                                        <span>Led by a healthcare veteran, assembled a multi-disciplinary team</span>
                                    </li>
                                    <li className="flex items-start">
                                        <i className="fa-sharp fa-solid fa-circle-check mr-4 mt-1 text-burnt-sienna-500"></i>
                                        <span>Gathered critical details on overall processes of the acquisition target: clinical, finance, quality, compliance, assets, contracts, and technology systems</span>
                                    </li>
                                    <li className="flex items-start">
                                        <i className="fa-sharp fa-solid fa-circle-check mr-4 mt-1 text-burnt-sienna-500"></i>
                                        <span>Conducted a break-even analysis with a road map to turn around, identifying potential opportunities, an implementation plan, and a timeline</span>
                                    </li>
                                    <li className="flex items-start">
                                        <i className="fa-sharp fa-solid fa-circle-check mr-4 mt-1 text-burnt-sienna-500"></i>
                                        <span>Setup a transition plan mapping people, processes and technology</span>
                                    </li>
                                    <li className="flex items-start">
                                        <i className="fa-sharp fa-solid fa-circle-check mr-4 mt-1 text-burnt-sienna-500"></i>
                                        <span>Post-acquisition, deployed a multi-disciplinary team on ground in key operational roles</span>
                                    </li>
                                    <li className="flex items-start">
                                        <i className="fa-sharp fa-solid fa-circle-check mr-4 mt-1 text-burnt-sienna-500"></i>
                                        <span>Identified vulnerable systems, deployed cyber security prevention and monitoring systems</span>
                                    </li>
                                    <li className="flex items-start">
                                        <i className="fa-sharp fa-solid fa-circle-check mr-4 mt-1 text-burnt-sienna-500"></i>
                                        <span>Deployed cloud based technology systems in critical areas such as ED, Surgery, Health Information Management, Business Office, and Supplies to optimize cost and improve collections </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="section">
                            <p className="font-semibold mt-8 mb-4">Results:</p>
                            <p>The acquisition was successful. The hospital's financial turnaround was ahead of schedule and was achieved cost-effectively. Patients in the region were happy that they had reliable access to world-class health care in their area and did not need to drive 50 miles to the nearest town.</p>
                        </div>
                    </div>

                </div>
            </div>
        </div >
    </>)

}
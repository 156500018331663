import { Helmet } from "react-helmet-async";

export default function WhoWeAre() {
    return (<>
        <Helmet>
            <title>Who We Are | Velozent</title>
        </Helmet>
        <div className="bg-shark-950 text-white py-16 lg:py-20 px-4 bg-none lg:bg-white-dots-reverse bg-no-repeat bg-right-top-56">
            <div className="container mx-auto">
                <p className="font-bold text-3xl lg:text-5xl mb-20 text-center">We are Velozent</p>
                <div className="grid lg:grid-rows-2 gap-y-8 lg:gap-y-0 lg:px-10">
                    <div className="lg:max-w-lg pl-8 py-4 border-l border-gray-400">
                        <p className="font-medium text-3xl mb-6">World Leading Healthcare Technology Solutions</p>
                        <p className="text-gray-300">Velozent was founded by a group of Healthcare IT leaders with decades of experience in modern healthcare transformation and innovation. These outstanding individuals have carefully selected team members to deliver the highest quality services with speed and efficiency.</p>
                    </div>
                    <div className="lg:max-w-lg lg:ml-auto">
                        <p className="pl-8 py-4 border-l border-gray-400">Our leadership team is comprised of CIOs, CISOs and CTOs who have devoted their careers to the intersection of healthcare and technology. Velozent’s headquarters is in Southern California however our resources are strategically located across the nation. Frequently, our clients require round-the-clock attention so having team members nearby is a great advantage.</p>
                    </div>
                </div>
            </div>
        </div>
        <div className="bg-burnt-sienna-500 text-white py-16 lg:py-20 px-4">
            <div className="container mx-auto">
                <div className="grid grid-cols-1 lg:grid-cols-2">
                    <img src="/assets/images/what_we_do.png" alt="solutions" className="lg:h-[400px] mx-auto my-auto"/>
                    <div className="my-8">
                        <p className="text-3xl font-semibold">Our Solutions</p>
                        <ul className="divide-y divide-gray-100 mt-8">
                            <li className="py-2">Speed of transformation</li>
                            <li className="py-2">Cost-control measures</li>
                            <li className="py-2">Implementation speed</li>
                            <li className="py-2">Swift decision making for agile project management</li>
                            <li className="py-2">Lessons learned via 100s of EMR and complete system implementations</li>
                            <li className="py-2">Efficient training methods for every user based on user role</li>
                            <li className="py-2">Playbook ready for Mergers & Acquisitions</li>
                            <li className="py-2">IT transitions to reduce TSA costs</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </>)

}
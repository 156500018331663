import { ReactSVG } from "react-svg";

export default function Techcard({ title, description, highlight, icon }) {
    return (
        <div className={`p-8 rounded-lg ${highlight ? 'bg-burnt-sienna-400 text-white' : 'bg-transparent'}`}>
            <ReactSVG className={`mb-10 h-8  ${highlight?'text-white':'text-burnt-sienna-500'}`} src={`/assets/images/${icon}`}/>
            <p className="text-xl font-medium">{title}</p>
            <p className="mt-2">{description}</p>
        </div>
    )
}
function Services() {
    return (
        <div className="bg-orange-100 py-20 px-4">
            <div className="container mx-auto">
                <div className="grid grid-cols-1 lg:grid-cols-5 gap-4">
                    <div className="col-span-1 lg:col-span-2">
                        <p className="text-sm text-burnt-sienna-600">Stress free upgrade</p>
                        <p className="pt-2 text-3xl font-semibold">Our Services</p>
                        <p className="pt-6 max-w-md">Our team provides a wide range of support through IT infrastructure, service desk and a dedicated CIO. Our services include:</p>
                    </div>
                    <ul className="grid col-span-1 lg:col-span-3 grid-cols-1 lg:grid-cols-2 gap-x-4 mt-8">
                        <li className="py-2">
                            <i className="fa-sharp fa-solid fa-circle-check mr-4 text-burnt-sienna-500"></i>
                            <span>Cloud Storage</span>
                        </li>
                        <li className="py-2">
                            <i className="fa-sharp fa-solid fa-circle-check mr-4 text-burnt-sienna-500"></i>
                            <span>Network Infrastructure</span>
                        </li>
                        <li className="py-2">
                            <i className="fa-sharp fa-solid fa-circle-check mr-4 text-burnt-sienna-500"></i>
                            <span>Integrations</span>
                        </li>
                        <li className="py-2">
                            <i className="fa-sharp fa-solid fa-circle-check mr-4 text-burnt-sienna-500"></i>
                            <span>Medical Systems</span>
                        </li>
                        <li className="py-2">
                            <i className="fa-sharp fa-solid fa-circle-check mr-4 text-burnt-sienna-500"></i>
                            <span>Electronic Medical Record</span>
                        </li>
                        <li className="py-2">
                            <i className="fa-sharp fa-solid fa-circle-check mr-4 text-burnt-sienna-500"></i>
                            <span>Data Management</span>
                        </li>
                        <li className="py-2">
                            <i className="fa-sharp fa-solid fa-circle-check mr-4 text-burnt-sienna-500"></i>
                            <span>Innovation</span>
                        </li>
                        <li className="py-2">
                            <i className="fa-sharp fa-solid fa-circle-check mr-4 text-burnt-sienna-500"></i>
                            <span>Digital Marketing</span>
                        </li>
                        <li className="py-2">
                            <i className="fa-sharp fa-solid fa-circle-check mr-4 text-burnt-sienna-500"></i>
                            <span>Consulting</span>
                        </li>
                        <li className="py-2">
                            <i className="fa-sharp fa-solid fa-circle-check mr-4 text-burnt-sienna-500"></i>
                            <span>Cyber Security Solutions</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Services;
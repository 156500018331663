import { useState } from "react";

export default function HealthCare() {
    const items = [
        {
            id: 1,
            title: "Managed Services",
            items: [
                "Proactive monitoring and management of onsite resources",
                "Comprehensive consulting services",
                "Hardware and software procurement",
                "Around-the-clock IT support",
                "Patch management",
                "Backup and Disaster Recovery (BDR)"
            ],
        },
        {
            id: 2,
            title: "Application implementation",
            items: [
                "Evaluation and selection of applications, implementation planning, infrastructure recommendations",
                "Multi-skilled project teams for end-to-end project implementations"
            ],
        },
        {
            id: 3,
            title: "Infrastructure Support",
            items: [
                "Hardware, Network, Storage, Security, Servers",
                "Software Applications",
                "Wireless Infrastructure Services",
            ],
        },
        {
            id: 4,
            title: "Applications Support – Onsite & Offsite",
            items: [
                "Support various healthcare applications with seasoned team members",
                "Flexible support options: onsite, off-site, and hybrid models"
            ],
        },
        {
            id: 5,
            title: "Data Analytics",
            items: [
                "Deep expertise in building and delivering Executive Dashboards with Key Performance Indicators",
                "Operational dashboards and reports that address departmental needs",
                "Tableau, PowerBI, Crystal Reports",
            ],
        },
        {
            id: 6,
            title: "Compliance Reporting",
            items: [
                "Well-versed in compliance reporting requirements: Quality, DSH and Core Measures",
                "Build comprehensive reporting systems sourcing data from various operational systems ",
                "Expertise in Federal, State, and Local agencies periodic reporting requirements: Supply Chain, Payroll, Medicare, Medicaid",
            ],
        }
    ];
    const customers = [
        {
            id: 1,
            title: "National Managed Services Organization",
            items: [
                "Stabilized the overall technology and operational processes caused by high employee turnover. Augmented with onsite and offsite teams for help desk, applications, infrastructure, security and compliance reporting.",
                "Successfully finished two key legacy to cloud migration projects related to care management, quality and risk measures under tight deadlines;",
                "Enabled timely compliance report generation; Automated key data, reporting and support processes; Annual benefits in excess of $2 Million"
            ],
        },
        {
            id: 2,
            title: "A 400 bed Southern California CA Health System",
            items: [
                "Providing comprehensive services including C-Suite Advisory Services, Office 365 Implementation, EMR Optimization, Data Center to Cloud Migration, Security Assessment & Remediation, Upgrade Wireless Infrastructure amongst other services.  First year, cost savings exceeded $15 Million.",
                "Championed digital transformation across the system; Enabled Health System to receive the prestigious HIMMS EMRAM Stage 7 award"
            ],
        },
        {
            id: 3,
            title: "A 500 bed Philadelphia Academic Medical Center",
            items: [
                "Stabilized the EMR implementation project which was behind schedule and over budget; reevaluated the hospital needs, optimized project schedule, cost, and resources.  Benefits: First year cost savings $10M",
            ],
        }]
    const [tabIndex, setTabIndex] = useState(1)

    return (
        <>
            <div className="bg-gray-100 py-8 lg:py-12 px-4">
                <div className="container mx-auto">
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-y-4 lg:gap-y-0">
                        <img
                            src="/assets/images/what_we_do.png"
                            alt="solutions"
                            className="lg:h-[400px] mx-auto my-auto"
                        />
                        <div>
                            <div className="pl-8 py-4 border-l border-gray-400">
                                <p className="font-medium text-3xl mb-6">Healthcare IT Services </p>
                                <p>
                                Elevate your Healthcare Experience with our IT Services – where innovation meets patient care. Unleash the Power of Healthtech Excellence, ensuring seamless operations and exceptional care in every digital heartbeat.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 mt-16 lg:mt-36 lg:mb-24">
                        {
                            items.map((obj) => (
                                <div className="bg-white shadow-sm px-8 py-10" key={obj.id}>
                                    <p className="text-2xl mb-4 font-semibold">{obj.title}</p>
                                    <ul>
                                        {
                                            obj.items.map((item, index) => (
                                                <li className="py-1 flex items-start" key={index}>
                                                    <i className="fa-sharp fa-solid fa-circle-check mt-1 mr-4 text-burnt-sienna-300"></i>
                                                    <span>{item}</span></li>
                                            ))
                                        }
                                    </ul>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
            <div className="bg-burnt-sienna-500 text-white px-4">
                <div className="container mx-auto">
                    <div className="py-24">
                        <p className="font-medium text-3xl mb-6 text-center">Recent customer success</p>
                        <div className="lg:px-8 py-10 min-h-[250px]">
                            <p className="text-2xl mb-4 font-semibold">{customers[tabIndex - 1].title}</p>
                            <ul>
                                {
                                    customers[tabIndex - 1].items.map((item, index) => (
                                        <li className="py-1 flex items-start" key={index}>
                                            <i className="fa-sharp fa-solid fa-circle-check mt-1 mr-4 text-burnt-sienna-300"></i>
                                            <span>{item}</span></li>
                                    ))
                                }
                            </ul>
                        </div>
                        <ul className="flex space-x-4 justify-center mt-16">
                            {
                                customers.map((item) => (
                                    <li key={item.id} className={`rounded-sm p-1 cursor-pointer w-6 text-sm ${item.id === tabIndex ? ' bg-white' : 'bg-gray-300'}`} onClick={() => setTabIndex(item.id)}>
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
}
